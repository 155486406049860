<template>
    <section class="ip-exchange">
        <Nav
            class="mt-5 mb-4"
            route-name="IPExchange"
            parent-page-name="Трансфер технологий"
            current-page-name="Проект «IP Биржа»"
        />

        <div class="ip-exchange__main-img d-none d-xl-flex">
            <div class="ip-exchange__blurred-img mt-lg-auto ms-lg-auto d-flex align-items-center ms-lg-4">
                <h2 class="technopark__title mt-auto ms-lg-5">
                    Проект «IP Биржа»
                </h2>
                <img
                    src="../../assets/page-logo.svg"
                    class="research-center__logo ms-auto mb-5 me-5 mt-auto d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="title-wrapper d-xl-none my-4 my-lg-5">
            <h2 class="technopark__title technopark__title--dark">
                Проект «IP Биржа»
            </h2>
        </div>

        <div class="research-center__task py-5 d-flex flex-column flex-lg-row align-items-center">
            <div class="col-12 col-lg-8 col-xl-6">
                <img
                    class="task__img"
                    src="../../assets/views/ip-exchange/ip-birzha.png"
                    alt=""
                    width="588"
                    height="376"
                >
            </div>

            <div class="col-12 col-lg-4 col-xl-6 d-flex flex-column align-items-center align-items-lg-start mt-4 me-lg-5">
                <h3 class="task__title">О проекте</h3>
                <div class="task__text mt-3">
                    Проект «IP Биржа» - это студенческое патентное агентство, которое проводит научно-консультационную
                    работу в сфере патентно-правовой поддержки университетских проектов. Студенческие команды
                    осуществляют консультации при обязательном научном руководстве профильных специалистов в области
                    управления интеллектуальной собственностью, в том числе российских и международных патентных
                    поверенных.
                </div>
                <div class="task__text mt-3">
                    Проект «IP Биржа» нацелен на успешную коммерциализацию студенческих разработок посредством
                    формирования стратегии управления интеллектуальной собственностью и подбора необходимых и
                    достаточных способов правовой охраны результатов интеллектуальной деятельности проекта.
                </div>

                <Button
                    class="mt-5"
                    @click="goToRegistrationForm"
                >
                    <template #default>
                        Зарегистрироваться на консультацию
                    </template>

                    <template #icon>
                        <img
                            src="../../assets/components/header/arrow-right.svg"
                            alt=""
                            class="button-icon"
                        >
                    </template>
                </Button>
            </div>
        </div>

        <div class="technopark-numbers my-5">
            <div class="technopark-numbers__title mb-5">Практика взаимодействия с проектами</div>

            <div class="d-flex flex-column align-items-center flex-xxl-row align-items-xxl-start">
                <div class="mx-auto">
                    <img class="practice-card__img" src="../../assets/views/ip-exchange/project-selection.svg" alt="">
                </div>
                <div class="mx-auto mt-3 mt-xxl-0">
                    <img class="practice-card__img" src="../../assets/views/ip-exchange/work-with-projects.svg" alt="">
                </div>
                <div class="mx-auto mt-3 mt-xxl-0">
                    <img class="practice-card__img" src="../../assets/views/ip-exchange/final-decision.svg" alt="">
                </div>
            </div>
        </div>

        <div class="ip-exchange__partnerhsip-benefits py-5">
            <div class="row">
                <div
                    class="col-lg-4 p-0 partnerhsip-benefit d-flex flex-column align-content-center justify-content-center mx-auto">
                    <div class="mx-auto"><img src="../../assets/views/ip-exchange/check.png" alt="" width="74"
                                              height="74"></div>
                    <div class="partnership-benefit__text mt-3">Патентный поиск</div>
                </div>
                <div
                    class="col-lg-4 p-0 partnerhsip-benefit d-flex flex-column align-content-center justify-content-center mx-auto">
                    <div class="mx-auto"><img src="../../assets/views/ip-exchange/check.png" alt="" width="74"
                                              height="74"></div>
                    <div class="partnership-benefit__text mt-3">Подготовка комплекта документов для регистрации РИД
                    </div>
                </div>
                <div
                    class="col-lg-4 p-0 partnerhsip-benefit d-flex flex-column align-content-center justify-content-center mx-auto">
                    <div class="mx-auto"><img src="../../assets/views/ip-exchange/check.png" alt="" width="74"
                                              height="74"></div>
                    <div class="partnership-benefit__text mt-3">R&D Антураж</div>
                </div>
                <div
                    class="col-lg-4 p-0 partnerhsip-benefit d-flex flex-column align-content-center justify-content-center mx-auto">
                    <div class="mx-auto"><img src="../../assets/views/ip-exchange/check.png" alt="" width="74"
                                              height="74"></div>
                    <div class="partnership-benefit__text mt-3">Предложение по коммерциализации</div>
                </div>
                <div
                    class="col-lg-4 p-0 partnerhsip-benefit d-flex flex-column align-content-center justify-content-center mx-auto">
                    <div class="mx-auto"><img src="../../assets/views/ip-exchange/check.png" alt="" width="74"
                                              height="74"></div>
                    <div class="partnership-benefit__text mt-3">Патентный ландшафт</div>
                </div>
            </div>
        </div>

        <div class="ip-exchange__form my-5 py-5">
            <div class="mb-5">
                <a href="registrationForm" class="registrationForm registration-form__title technopark-numbers__title">
                    Форма регистрации
                </a>
            </div>

            <div class="row d-flex">
                <form class="registration-form row" @submit.prevent="onSubmit">
                    <div class="col-md-6 col-lg-5">
                        <div class="mb-3">
                            <label for="fullname" class="parnter-form__label form-label">ФИО</label>
                            <input
                                id="fullname"
                                v-model="form.fullname"
                                type="text"
                                class="parnter-form__input form-control"
                                required
                            >
                        </div>

                        <div class="mb-3">
                            <label for="organization" class="parnter-form__label form-label">Организация</label>
                            <input
                                id="organization"
                                type="text"
                                v-model="form.organization"
                                class="parnter-form__input form-control"
                                required
                            >
                        </div>

                        <div class="mb-3">
                            <label for="email" class="parnter-form__label form-label">Email</label>
                            <input
                                id="email"
                                v-model="form.email"
                                type="email"
                                class="parnter-form__input form-control"
                                aria-describedby="emailHelp"
                                required
                            >
                        </div>

                        <div class="mb-3">
                            <label for="project_stage" class="parnter-form__label form-label">
                                На какой стадии находится Ваш проект:</label>

                            <div>
                                <input
                                    :checked="form.project_stages === 'Идея'"
                                    @click="form.project_stages = 'Идея'"
                                    type="checkbox"
                                    id="Идея"
                                    name="Идея"
                                    value="Идея"
                                >
                                <label for="Идея" class="registration-form__checkbox ms-2">Идея</label><br>

                                <input
                                    :checked="form.project_stages === 'Разработка'"
                                    @click="form.project_stages = 'Разработка'"
                                    type="checkbox"
                                    id="Разработка"
                                    name="Разработка"
                                    value="Разработка"
                                >
                                <label for="Разработка" class="registration-form__checkbox ms-2">Разработка</label><br>

                                <input
                                    :checked="form.project_stages === 'Первые продажи'"
                                    @click="form.project_stages = 'Первые продажи'"
                                    type="checkbox"
                                    id="Первые продажи"
                                    name="Первые продажи"
                                    value="Первые продажи"
                                >
                                <label for="Первые продажи" class=" registration-form__checkbox ms-2">
                                    Первые продажи
                                </label><br>

                                <input
                                    :checked="form.project_stages === 'Стабилизация на рынке'"
                                    @click="form.project_stages = 'Стабилизация на рынке'"
                                    type="checkbox"
                                    id="Стабилизация на рынке"
                                    name="Стабилизация на рынке"
                                    value="Стабилизация на рынке"
                                >
                                <label for="Стабилизация на рынке" class="registration-form__checkbox ms-2">
                                    Стабилизация на рынке
                                </label><br>

                                <input
                                    :checked="form.project_stages === 'Уменьшение присутствия на рынке'"
                                    @click="form.project_stages = 'Уменьшение присутствия на рынке'"
                                    type="checkbox"
                                    id="Уменьшение присутствия на рынке"
                                    name="Уменьшение присутствия на рынке"
                                    value="Уменьшение присутствия на рынке"
                                >
                                <label for="Уменьшение присутствия на рынке" class="registration-form__checkbox ms-2">
                                    Уменьшение присутствия на рынке
                                </label><br>

                                <div class="d-flex align-items-center">
                                    <input
                                        :checked="form.project_stages === 'Другое'"
                                        @click="form.project_stages = 'Другое'"
                                        type="checkbox"
                                        id="Другое"
                                        name="Другое"
                                        value="Другое"
                                    >
                                    <label for="Другое" class="registration-form__checkbox ms-2">Другое</label><br>

                                    <div class="ms-2">
                                        <input
                                            id="project_stage"
                                            type="text"
                                            placeholder="Опишите стадию"
                                            v-model="form.project_stage"
                                            class="registration-form__checkbox parnter-form__input form-control"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mb-3">
                            <label for="problem" class="parnter-form__label form-label">Какую проблему решает ваш
                                проект?</label>
                            <input
                                id="problem"
                                type="text"
                                v-model="form.problem"
                                class="parnter-form__input form-control"
                                required
                            >
                        </div>

                        <div class="mb-3">
                            <label for="short_desc" class="parnter-form__label form-label">Краткое описание проекта</label>
                            <textarea
                                v-model="form.short_desc"
                                placeholder="Расскажите о своем проекте"
                                class="registration-form__checkbox form-control parnter-form__textarea"
                                rows="4"
                                id="short_desc"
                                required
                            />
                        </div>
                    </div>

                    <div class="col-md-6 ms-auto">
                        <div class="mb-3">
                            <label for="project_stage" class="parnter-form__label form-label">
                                Есть ли у Вас действующая команда проекта?</label>

                            <div>
                                <input
                                    :checked="form.project_teams === 'Да'"
                                    @click="form.project_teams = 'Да'"
                                    type="checkbox"
                                    id="Да"
                                    name="Да"
                                    value="Да"
                                >
                                <label for="Да" class="registration-form__checkbox ms-2">Да</label><br>

                                <input
                                    :checked="form.project_teams === 'Да, частично'"
                                    @click="form.project_teams = 'Да, частично'"
                                    type="checkbox"
                                    id="Да, частично"
                                    name="Да, частично"
                                    value="Да, частично"
                                >
                                <label for="Да, частично" class="registration-form__checkbox ms-2">Да, частично</label><br>

                                <input
                                    :checked="form.project_teams === 'Я единственный член команды'"
                                    @click="form.project_teams = 'Я единственный член команды'"
                                    type="checkbox"
                                    id="Я единственный член команды"
                                    name="Я единственный член команды"
                                    value="Я единственный член команды"
                                >
                                <label for="Я единственный член команды" class="registration-form__checkbox ms-2">
                                    Я единственный член команды
                                </label><br>

                                <div class="d-flex align-items-center">
                                    <input
                                        :checked="form.project_teams === 'Другое'"
                                        @click="form.project_teams = 'Другое'"
                                        type="checkbox"
                                        id="Другое2"
                                        name="Другое2"
                                        value="Другое"
                                    >
                                    <label for="Другое2" class="registration-form__checkbox ms-2">Другое</label><br>

                                    <div class="ms-2">
                                        <input
                                            id="project_stage"
                                            type="text"
                                            placeholder="Опишите команду"
                                            v-model="form.project_team"
                                            class="registration-form__checkbox parnter-form__input form-control"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mb-3">
                            <label for="project_stage" class="parnter-form__label form-label">
                                Принимал ли Ваш проект участие в акселерационных программах?
                            </label>

                            <div>
                                <input
                                    :checked="form.programs === 'Да'"
                                    @click="form.programs = 'Да'"
                                    type="checkbox"
                                    id="Да2"
                                    name="Да2"
                                    value="Да">
                                <label for="Да2" class="registration-form__checkbox ms-2">Да</label><br>

                                <input
                                    :checked="form.programs === 'Нет'"
                                    @click="form.programs = 'Нет'"
                                    type="checkbox"
                                    id="Нет"
                                    name="Нет"
                                    value="Нет">
                                <label for="Нет" class="registration-form__checkbox ms-2">Нет</label><br>

                                <input
                                    :checked="form.programs === 'Не дошел до финального этапа'"
                                    @click="form.programs = 'Не дошел до финального этапа'"
                                    type="checkbox"
                                    id="Не дошел до финального этапа"
                                    name="Не дошел до финального этапа"
                                    value="Не дошел до финального этапа"
                                >
                                <label for="Не дошел до финального этапа" class="registration-form__checkbox ms-2">
                                    Не дошел до финального этапа
                                </label><br>

                                <div class="d-flex align-items-center">
                                    <input
                                        :checked="form.programs === 'Другое'"
                                        @click="form.programs = 'Другое'"
                                        type="checkbox"
                                        id="Другое3"
                                        name="Другое3"
                                        value="Другое"
                                    >
                                    <label for="Другое3" class="registration-form__checkbox ms-2">Другое</label><br>

                                    <div class="mb-3 ms-2">
                                        <input
                                            id="project_stage"
                                            type="text"
                                            v-model="form.program"
                                            class="parnter-form__input form-control"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mb-3">
                            <label for="project_stage" class="parnter-form__label form-label">
                                Предмет консультации/сопровождения проекта
                            </label>

                            <div>
                                <input
                                    :checked="form.subjects === 'Патентование'"
                                    @click="form.subjects = 'Патентование'"
                                    type="checkbox"
                                    id="Патентование"
                                    name="Патентование"
                                     value="Патентование"
                                >
                                <label for="Патентование" class="registration-form__checkbox ms-2">Патентование</label><br>

                                <input
                                    :checked="form.subjects === 'Регистрация товарного знака'"
                                    @click="form.subjects = 'Регистрация товарного знака'"
                                    type="checkbox"
                                    id="Регистрация товарного знака"
                                    name="Регистрация товарного знака"
                                    value="Регистрация товарного знака"
                                >
                                <label for="Регистрация товарного знака" class="registration-form__checkbox ms-2">
                                    Регистрация товарного знака</label><br>

                                <input
                                    :checked="form.subjects === 'Разработка стратегии охраны интеллектуальной собственности проекта'"
                                    @click="form.subjects = 'Разработка стратегии охраны интеллектуальной собственности проекта'"
                                    type="checkbox"
                                    id="Разработка стратегии охраны интеллектуальной собственности проекта"
                                    name="Разработка стратегии охраны интеллектуальной собственности проекта"
                                    value="Разработка стратегии охраны интеллектуальной собственности проекта"
                                >
                                <label for="Разработка стратегии охраны интеллектуальной собственности проекта"
                                       class="registration-form__checkbox ms-2">
                                    Разработка стратегии охраны интеллектуальной собственности проекта
                                </label><br>

                                <div class="d-flex align-items-center">
                                    <input
                                        :checked="form.subjects === 'Другое'"
                                        @click="form.subjects = 'Другое'"
                                        type="checkbox"
                                        id="Другое4"
                                        name="Другое4"
                                        value="Другое"
                                    >
                                    <label for="Другое4" class="registration-form__checkbox ms-2">Другое</label><br>

                                    <div class="ms-2">
                                        <input
                                            id="project_stage"
                                            type="text"
                                            v-model="form.subject"
                                            class="parnter-form__input form-control"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mb-3">
                            <label for="main_question" class="parnter-form__label form-label">
                                Сформулируйте основной запрос к экспертам проекта
                            </label>
                            <textarea
                                v-model="form.main_question"
                                class="registration-form__checkbox form-control parnter-form__textarea"
                                rows="4"
                                placeholder="Сформулируйте запрос"
                                id="main_question"
                                required
                            />
                        </div>

                        <Button
                            variant="blue"
                            type="submit"
                        >
                            <template #default>
                                Зарегистрироваться на консультацию
                            </template>

                            <template #icon>
                                <img src="../../assets/components/header/arrow-right.svg" alt="" class="button-icon">
                            </template>
                        </Button>
                    </div>
                </form>
            </div>
        </div>

        <div class="ip-exchange__reviews my-5 py-5">
            <div class="technopark-numbers__title mb-5">Отзывы</div>

            <div class="row">
                <div class="col-md-6 review">
                    <div class="review-author d-flex">
                        <div class="review-author__img"><img src="../../assets/views/ip-exchange/boy.png" alt=""></div>
                        <div class="my-auto ms-4">
                            <div class="review-author__name">Зорикто Сапкеев</div>
                            <div class="review-author__position mt-3">Студент ИТМО</div>
                        </div>
                    </div>

                    <div class="review-card p-4 pb-5 mt-5">
                        <div class="review-card__title">
                            Проект по производству кастомизированной обуви по индивидуальным меркам
                        </div>
                        <div class="review__text mt-3">
                            Для осуществления проекта было необходимо разработать стратегию выхода на российский рынок
                            обуви. Я получил полезные консультации в области интеллектуальной собственности по возможным
                            путям развития проекта и рекомендации по конкретным способам патентования. В ходе работы был
                            предложен прототип товарного знака и изучен имеющийся патент, на предмет его соответствия
                            для использования на местном рынке. Наш ментор Владислав Сомонов инициировал множество идей
                            внутри проекта, а также оказал содействие в проработке бизнес-тезиса, за что хотелось бы
                            выразить ему и всей команде проекта огромную благодарность.
                        </div>
                    </div>
                </div>

                <div class="col-md-6 mt-4 mt-md-0">
                    <div class="review-author d-flex">
                        <div class="review-author__img"><img src="../../assets/views/ip-exchange/girl.png" alt=""></div>
                        <div class="my-auto ms-4">
                            <div class="review-author__name">Анастасия Столбовая</div>
                            <div class="review-author__position mt-3">Выпускница ИТМО</div>
                        </div>
                    </div>

                    <div class="review-card p-4 pb-5 mt-5">
                        <div class="review-card__title">
                            Проект по разработке персонализированных биодобавок
                        </div>
                        <div class="review__text mt-3">
                            В самом начале проекта за основу была взята технология пищевой 3D-печати, но далее по ходу
                            проекта было решено применить иную технологию - метод сферификации. В ходе работы по
                            программе IP Биржа была разработана стратегия управления интеллектуальной собственности
                            проекта. В ходе работы были проанализированы патенты со стороны 3D- разработок в пищевой
                            области, и, далее патенты пищевой продукции основанные на способе сертификации. Кроме того,
                            я смогла получить соответствующие рекомендации не только в области ИС, но также в
                            определенных вопросах по проекту и его проработке.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ip-exchange__lectures py-5">
            <div class="technopark-numbers__title mb-5">Лекции и вебинары от проекта IP Биржа</div>

            <div class="d-flex flex-column align-items-center flex-xl-row justify-content-xl-between">
                <div
                    class="lecture-card lecture-card--1 d-flex flex-column align-items-center p-3"
                    @click="goToLink('security')"
                >
                    <div class="lecture-card__title mt-4 mt-sm-5">Правовая охрана штаммов микроорганизмов</div>
                    <div class="lecture-card__author-name mt-2">Кирилл Обедин</div>
                    <div class="lecture-card__author-position">управляющий партнер "Обедин и партнеры"</div>
                </div>

                <div
                    class="lecture-card lecture-card--2 d-flex flex-column align-items-center p-3 mt-3 mt-xl-0 ms-xl-2 ms-xxl-0"
                    @click="goToLink('chemistry')"
                >
                    <div class="lecture-card__title mt-4 mt-sm-5">Патенты и химия</div>
                    <div class="lecture-card__author-name mt-2">Анна Лапа</div>
                    <div class="lecture-card__author-position">
                        главный специалист по интеллектуальной собственности ведущей российской биотехнологической фармацевтической компании
                    </div>
                </div>

                <div
                    class="lecture-card lecture-card--3 d-flex flex-column align-items-center p-3 mt-3 mt-xl-0 ms-xl-2 ms-xxl-0"
                    @click="goToLink('soundDesign')"
                >
                    <div class="lecture-card__title mt-3 mt-sm-5">Защита саунд-дизайна в приложениях и не только</div>
                    <div class="lecture-card__author-name mt-2">Виктор Горский-Мочалов</div>
                    <div class="lecture-card__author-position">старший юрист CLAIMS</div>
                </div>
            </div>

            <div class="d-flex flex-column align-items-center flex-xl-row justify-content-xl-between mt-xl-3">
                <div
                    class="lecture-card lecture-card--4 d-flex flex-column align-items-center p-3 mt-3 mt-xl-0"
                    @click="goToLink('IPandTech')"
                >
                    <div class="lecture-card__title mt-3 mt-sm-5">IP и Tech: нужны ли IP юристам IT-сервисы</div>
                    <div class="lecture-card__author-name mt-2">Ирина Шурмина</div>
                    <div class="lecture-card__author-position">старший юрист IP практики и руководитель Innovation Group CMS Russia, LL.M</div>
                </div>

                <div
                    class="lecture-card lecture-card--5 d-flex flex-column align-items-center p-3 mt-3 mt-xl-0 ms-xl-2 ms-xxl-0"
                    @click="goToLink('personalData')"
                >
                    <div class="lecture-card__title mt-2 mt-sm-5">Работа с персональными данными</div>
                    <div class="lecture-card__author-name mt-3">Алексей Мунтян</div>
                    <div class="lecture-card__author-position">
                        основатель и СЕО в компании Privacy Advocates, соучредитель RPPA
                    </div>
                </div>

                <div
                    class="lecture-card lecture-card--6 d-flex flex-column align-items-center p-3 mt-3 mt-xl-0 ms-xl-2 ms-xxl-0"
                    @click="goToLink('analytics')"
                >
                    <div class="lecture-card__title mt-sm-3">Технологическая разведка и патентная аналитика</div>
                    <div class="lecture-card__author-name mt-2">Марат Альмаганбетов</div>
                    <div class="lecture-card__author-position">директор по развитию бизнеса LexisNexis Russia; Eastern Europe</div>
                    <div class="lecture-card__author-name mt-2">Андрей Николаев</div>
                    <div class="lecture-card__author-position">доцент Университета ИТМО</div>
                </div>
            </div>
        </div>

        <div class="ip-exchange__useful-things mt-5">
            <div class="technopark-numbers__title mb-4">Полезные материалы</div>

            <div class="material-card p-4 d-flex flex-column flex-xl-row w-100">
                <div class="material-card__text">1. Учебное пособие «Патентная аналитика»</div>
                <div class="mt-2 mt-xl-0 ms-xl-auto">
                    <a href="http://books.ifmo.ru/file/pdf/3076.pdf" target="_blank"
                       class="material-card__link d-inline-block me-3">Перейти к материалам</a>
                    <img src="../../assets/views/ip-exchange/arrow-right.svg" alt="">
                </div>
            </div>

            <div class="material-card p-4 d-flex flex-column flex-xl-row w-100 mt-2">
                <div class="material-card__text">2. Руководство по регистрации изобретений</div>
                <div class="mt-2 mt-xl-0 ms-xl-auto">
                    <a href="https://rospatent.gov.ru/ru/documents/ruc-iz/download" target="_blank"
                       class="material-card__link d-inline-block me-3">Перейти к материалам</a>
                    <img src="../../assets/views/ip-exchange/arrow-right.svg" alt="">
                </div>
            </div>
            <div class="material-card p-4 d-flex flex-column flex-xl-row w-100 mt-2">
                <div class="material-card__text">3. Руководство по регистрации полезных моделей</div>
                <div class="mt-2 mt-xl-0 ms-xl-auto">
                    <a href="https://rospatent.gov.ru/ru/documents/ruc-pm/download" target="_blank"
                        class="material-card__link d-inline-block me-3">Перейти к материалам</a>
                    <img src="../../assets/views/ip-exchange/arrow-right.svg" alt="">
                </div>
            </div>
            <div class="material-card p-4 d-flex flex-column flex-xl-row w-100 mt-2">
                <div class="material-card__text">4. Руководство по регистрации промышленных образцов</div>
                <div class="mt-2 mt-xl-0 ms-xl-auto">
                    <a href="https://rospatent.gov.ru/ru/documents/rucov-po/download" target="_blank"
                        class="material-card__link d-inline-block me-3">Перейти к материалам</a>
                    <img src="../../assets/views/ip-exchange/arrow-right.svg" alt="">
                </div>
            </div>
            <div class="material-card p-4 d-flex flex-column flex-xl-row w-100 mt-2">
                <div class="material-card__text">5. Руководство по регистрации программы для ЭВМ и базы данных</div>
                <div class="mt-2 mt-xl-0 ms-xl-auto">
                    <a href="https://rospatent.gov.ru/ru/documents/ruc_evm_bd/download" target="_blank"
                        class="material-card__link d-inline-block me-3">Перейти к материалам</a>
                    <img src="../../assets/views/ip-exchange/arrow-right.svg" alt="">
                </div>
            </div>
            <div class="material-card p-4 d-flex flex-column flex-xl-row w-100 mt-2">
                <div class="material-card__text">6. Руководство по регистрации товарного знака</div>
                <div class="mt-2 mt-xl-0 ms-xl-auto">
                    <a href="https://rospatent.gov.ru/ru/documents/rucov-tz/download" target="_blank"
                        class="material-card__link d-inline-block me-3">Перейти к материалам</a>
                    <img src="../../assets/views/ip-exchange/arrow-right.svg" alt="">
                </div>
            </div>
        </div>
    </section>

    <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
        <div id="successRegistrationToast" class="toast hide bg-success text-white" style="--bs-success-rgb: 82, 169, 156" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header">
                <strong class="me-auto">Успешно</strong>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body">
                Заявка на регистрацию успешно отправлена!
            </div>
        </div>
    </div>
</template>

<script>
import Nav from "@/components/Nav/Nav";
import Button from "@/components/common/Button/Button";
import { onMounted, ref } from "vue";
import routerMixin from "@/mixins/routerMixin";
import {useStore} from "vuex";
import {Toast} from "bootstrap";
import {computed} from "vue";

export default {
    name: "IPExchange",

    components: {
        Nav,
        Button
    },

    setup() {
        const { isMenuVisible, toggleMenu } = routerMixin();

        const store = useStore()

        let form = ref({
            fullname: '',
            organization: '',
            email: '',
            project_stage: '',
            project_stages: '',
            problem: '',
            project_team: '',
            project_teams: '',
            program: '',
            programs: '',
            subject: '',
            subjects: '',
            main_question: ''
        })

        const cardsLinks = ref({
            security: 'https://vk.com/video-201599223_456239047',
            chemistry: 'https://youtu.be/CE-4U3W3Rh4',
            soundDesign: 'https://youtu.be/ovp8Zjr11zc ',
            IPandTech: 'https://vk.com/video-201599223_456239033',
            personalData: 'https://vk.com/video-201599223_456239032',
            analytics: 'https://youtu.be/HfpYqKWrs5U',
        })

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        const goToLink = linkName => {
            let link = null;
            for (const key in cardsLinks.value) {
                if (key === linkName) {
                    link = cardsLinks.value[key]
                }
            }

            const url = document.createElement('a');
            url.href = link;
            url.target = '_blank';
            const event = new MouseEvent('click', {
                'view': window,
                'bubbles': false,
                'cancelable': true
            });
            url.dispatchEvent(event);
        }

        const goToRegistrationForm = () => {
            const anchor = document.querySelectorAll('a.registrationForm')[0]

            anchor.addEventListener('click', function (e) {
                e.preventDefault()

                document.querySelector('.registrationForm').scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                })
            })

            anchor.click()
        }

        const onSubmit = async (event) => {
            const application = JSON.parse(JSON.stringify(form.value))

            if (!application.project_stages.length && !application.project_stage) return
            if (!application.project_teams.length && !application.project_team) return
            if (!application.programs.length && !application.program) return
            if (!application.subjects.length && !application.subject) return

            const request = {
                fullname: application.fullname,
                organization: application.organization,
                email: application.email,
                project_stage: '',
                problem: application.problem,
                short_desc: application.short_desc,
                project_team: '',
                program: '',
                subject: '',
                main_question: application.main_question,
            };

            if (application.project_stages.length) {
                request.project_stage = application.project_stages.toString()
                request.project_stage = `${request.project_stage},${application.project_stage}`
            }

            if (application.project_teams.length) {
                request.project_team = application.project_teams.toString()
                request.project_team = `${request.project_team},${application.project_team}`
            }

            if (application.programs.length) {
                request.program = application.programs.toString()
                request.program = `${request.program},${application.program}`
            }

            if (application.subjects.length) {
                request.subject = application.subjects.toString()
                request.subject = `${request.subject},${application.subject}`
            }

            await store.dispatch('ipExchange/sendPartnerApplciation', request)

            const isRegistrationApplicationSent = store.getters['ipExchange/registrationApplicationSent']

            if (isRegistrationApplicationSent) {
                showSuccessToast()
                event.target.reset()
                document.querySelector('#partnerModal .btn-close').click()
            }
        }

        const isRegistrationApplicationSent = computed(() => store.getters['ipExchange/registrationApplicationSent'])

        const showSuccessToast = () => {
            if (isRegistrationApplicationSent.value) {
                const toastEl = document.querySelector('#successRegistrationToast')
                const toast = new Toast(toastEl)
                toast.show()

                store.dispatch('ipExchange/setRegistrationApplicationSent', false)
            }
        }

        return {
            form,
            cardsLinks,
            goToLink,
            goToRegistrationForm,
            onSubmit
        }
    }
}
</script>
